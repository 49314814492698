import React, {Component} from "react";
import PropTypes from "prop-types";
// @material-ui/core components
//import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { withStyles } from '@material-ui/core/styles';
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";

//const useStyles = makeStyles(styles);

import jconf from 'cvex-config.json'

class UserTable extends Component {
  constructor(props){
    super(props);
    this.tableHeaderColor=props.tableHeaderColor;
    this.tableHead = props.tableHead;
    this.state = {
      tableData: [],
      username: "",
      cvexes: ""
    };
    this.show = props.show;

  }
  componentDidMount() {
    document.title = "CVEX Scoreboard";
    this.loadSBData();
  }

  loadSBData(){
    let url = `${jconf.DB_API}/userdata?show=${this.show}`
    fetch(url, {method: 'GET',})
      .then(response => response.json().then(body => ({body, status: response.status})))
      .then(({body, status}) => {
        if (status !== 200) {
          console.log(status);
          console.log(body.message);
          return;
        }
        console.log(body)
        this.setState({tableData:body.tableData})

      })
      .catch((error) => {
        console.log(error);
      });

  }

  render() {
    let current = this.state ;
    const {classes} = this.props;
    const positionPopup = (clientX, clientY) => {
      let popup = document.getElementById("cvexpopup");
      let popup_rect = popup.getBoundingClientRect();
      var rect = popup.parentNode.getBoundingClientRect();
      var x = clientX - rect.left; //x position within the element.
      var y = clientY - rect.top;  //y position within the element.

      x = x - popup_rect.width
      y = y + 10
      popup.style.left = `${x}px`
      popup.style.top = `${y}px`

    }
    const handleMove = (e) =>{
      let popup = document.getElementById("cvexpopup");
      popup.style.display = "block"
      positionPopup(e.clientX, e.clientY)
      let un = e.target.parentNode.getAttribute("username")
      let cvexes = e.target.parentNode.getAttribute("cvexes")
      this.setState({username: un, cvexes: cvexes})
      //popup.innerHTML = `${un}</br>`
    };

    const handleClose = () => {
      let popup = document.getElementById("cvexpopup");
      popup.style.display = "none"

    }

    return (
      <div className={classes.tableResponsive} onMouseLeave={handleClose} >
        <div className={classes.cvexpopup}  id="cvexpopup"  >
            <div className={classes.cvexpopup_header} ><h4 style={{margin: "5px"}}>{this.state.username}</h4></div>
            {this.state.cvexes.split(",").map((prop, key) => {
              return (
                <div className={classes.cvexpopupitems} key={key}>{key+1}. {prop}</div>
              );
            })}

        </div>

        <Table className={classes.table}>
          {this.tableHead !== undefined ? (
            <TableHead className={classes[this.tableHeaderColor + "TableHeader"]}>
              <TableRow className={classes.tableHeadRow} >
                {this.tableHead.map((prop, key) => {
                  return (
                    <TableCell
                      className={classes.tableCell + " " + classes.tableHeadCell}
                      key={key}
                    >
                      {prop}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          ) : null}
          <TableBody>
            {current.tableData.map((prop, key) => {
              return (
                <TableRow key={key} className={classes.tableBodyRow} onMouseMove={handleMove.bind(this)} username={prop[1]} cvexes={prop[4]}>
                  {prop.slice(0,4).map((prop, key) => {
                    return (
                      <TableCell className={classes.tableCell} key={key}>
                        {prop}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  }
}

UserTable.defaultProps = {
  tableHeaderColor: "gray",
};

UserTable.propTypes = {
  classes: PropTypes.any,
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "info2",
    "info3",
    "info4",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  show: PropTypes.string,
};

export default withStyles(styles)(UserTable);