import {
  warningColor,
  primaryColor,
  dangerColor,
  successColor,
  infoColor,
    info3Color,
  roseColor,
  grayColor,
  defaultFont,
} from "assets/jss/material-dashboard-react.js";

const tableStyle = (theme) => ({
  warningTableHeader: {
    color: warningColor[0],
  },
  primaryTableHeader: {
    color: primaryColor[0],
  },
  dangerTableHeader: {
    color: dangerColor[0],
  },
  successTableHeader: {
    color: successColor[0],
  },
  infoTableHeader: {
    color: infoColor[0],
  },
  roseTableHeader: {
    color: roseColor[0],
  },
  grayTableHeader: {
    color: grayColor[0],
  },
  table: {
    marginBottom: "0",
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "transparent",
    borderSpacing: "0",
    borderCollapse: "collapse",
  },
  tableHeadCell: {
    color: "inherit",
    ...defaultFont,
    "&, &$tableCell": {
      fontSize: "1em",
    },
  },
  tableCell: {
    ...defaultFont,
    lineHeight: "1.42857143",
    padding: "12px 8px",
    verticalAlign: "middle",
    fontSize: "0.8125rem",
  },
  tableCellNoWrap: {
    fontSize: "0.8125rem",
 },
  tableResponsive: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  tableHeadRow: {
    height: "56px",
    color: "inherit",
    display: "table-row",
    outline: "none",
    verticalAlign: "middle",
  },
  tableBodyRow: {
    height: "48px",
    color: "inherit",
    display: "table-row",
    outline: "none",
    verticalAlign: "middle",
  },
  rowcolor: {
    color: "black"
  },
  arrowcolor: {
    color: infoColor[2]
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: "none"
  },
  paper: {
    position: 'absolute',
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modalbox:{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90%",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  },
  buttonboxtop:{
    position: 'absolute',
    top: '-55px',
    left: "70%",
    width: "28%",
    zIndex: 99
  },
  buttonboxtopSearch:{
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: "100%",
    zIndex: 99
  },
  buttonboxbottom:{
    position: 'absolute',
    top: '100%',
    left: '88%',
    width: "90%",
  },
  dirbuttontop:{
    marginRight:"10px",
    paddingRight:"10px",
    float: "right",
  },
  dirbuttonbottom:{
    marginRight:"10px",
    paddingRight:"10px",
    float: "right, bottom"
  },
  usermsg:{
    position: 'absolute',
    top: '10px',
    left: '5%',
    width: "90%",
    color: dangerColor[0],
  },
  cvexpopup:{
    backgroundColor: theme.palette.background.paper,
    position:'absolute',
    display:'none',
    minHeight:'200px',
    top:'200px',
    width:'200px',
    boxShadow: theme.shadows[5],
    borderRadius: "6px",
  },
  cvexpopup_header:{
      width: "100%",
      backgroundColor: info3Color[2],
      marginTop: "-20px",
      paddingTop: "5px",
      paddingBottom: "5px",
      borderRadius: "calc(.25rem - 1px) calc(.25rem - 1px) 0 0",
  },
  cvexpopupitems: {
      paddingLeft: "5px",
      paddingTop: "2px"
  },
  cvexcell: {
    minWidth: "115px",
  },
 
  positiondata:{
    position:"absolute",
    right:"0px",
    top:"-15px",
    width:"90px",
    textAlign:"center",
    marginRight:"10px",
    color: "#cccccc"
  },
  cvexlink:{
    color: info3Color[3]
  }
});

export default tableStyle;
