import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { withStyles } from '@material-ui/core/styles';
// core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import Markdown from 'markdown-to-jsx';
import SyntaxHighlighter from 'react-syntax-highlighter'
import jconf from 'cvex-config.json'

const styles = {
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#505050",
    display: "block",
    fontWeight: "300",
    fontSize: ".875rem",
    lineHeight: "1.5em",
    left: "0",
    marginLeft: "20px",
    marginRight: "20px",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  codeword: {
    backgroundColor: "rgb(240,240,240)",
    fontFamily: "ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace",
    margin: 0,
    fontSize: "85%",
    padding: "0.2em 0.4em",
    borderRadius: "6px",
    boxSizing: "border-box"
  },
  codebox: {
    backgroundColor: "rgb(240,240,240)",
    fontFamily: "ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace",
    margin: 0,
    fontSize: "85%",
    padding: "1.2em, 0.4em",
    borderRadius: "6px",
    boxSizing: "border-box"
  },
};

//const useStyles = makeStyles(styles);
const access_token='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'
const access_headers = {'Authorization':"Token "+access_token}

class  CVEXInfo extends React.Component {
  // this one runs on initial load of component
  constructor(props) {
    super(props);
    this.state ={
      readme: "Loading..."
    }
  }

  componentDidMount() {
    this.loadHowTo();
  }

  async loadHowTo() {
    console.log(jconf.DB_API, access_headers)
    let url = `${jconf.DB_API}/howto`;
    const response = await fetch (url, {method: 'GET', headers: access_headers })
    const gitresp = await response.json()

    let readme = gitresp.response
    readme = readme.replaceAll(/```y[aml]+/g,'\n```json')
    console.log(readme)
    this.setState({readme: readme})

    return {}
  }

  render() {
    const {classes} = this.props;

    const readme = this.state.readme ? this.state.readme : 'Loading...';
//     const Code = ({className, children}) => {
//       const language = className.replace("lang-", "");
//       console.log(language)
//       return (
// //          <SyntaxHighlighter language={language}>
//             <code>{children}</code>
// //          </SyntaxHighlighter>
//       );
//     }

    const CustomCode = ({ children, className, ...props }) => {


      if (children.indexOf('\n') > -1){
        const language = className.replace("lang-", "");
        console.log(language)
        return (
            <code {...props} className={classes.codebox}><SyntaxHighlighter language={language}>{children}</SyntaxHighlighter></code>
        );
      } else {
        return (
            <span {...props} className={classes.codeword}>{children}</span>
        );
      }

    };
    const h1toh4 =  ({ children, ...props }) => (
      <h4 {...props}>{children}</h4>
    );
    const h2toh5 =  ({ children, ...props }) => (
      <h5 {...props}>{children}</h5>
    );
    return (
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>How to Create a CVEX</h4>

          </CardHeader>

          <CardBody>
            <div className={classes.note}>
              <Markdown options={{
                overrides:{
                  code: {component: CustomCode, props:{}},
                  h1: h1toh4,
                  h2: h2toh5
                } }}>
                {readme}
              </Markdown>
            </div>
          </CardBody>
        </Card>
    );
  }


}
CVEXInfo.propTypes = {
  classes: PropTypes.any,
};

export default withStyles(styles)(CVEXInfo)