
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import Computer from "@material-ui/icons/Computer";
import UserIcon from "@material-ui/icons/SupervisedUserCircleOutlined";
//import LocationOn from "@material-ui/icons/LocationOn";

// core components/views for main layout
import ScoreboardPage from "views/Scoreboard/Scoreboard.js";
import CVEXSearch from "views/CVEX/CVEXSearch.js";
import CVEXInfo from "views/CVEX/CVEXInfo.js";
import CVEXPrereqs from "views/CVEX/CVEXPrereqs.js";
import CVEXRunInfo from "views/CVEX/CVEXRunInfo.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import UserList from "views/Users/UserList.js";
//import TableList from "views/TableList/TableList.js";

// core components/views for RTL layout
// {
//   path: "/table",
//     name: "Bounties",
//   icon: BubbleChart,
//   component: TableList,
//   layout: "/main",
//   visible: true,
//   dynamic_render:true
// },
// {
//   path: "/typography",
//     name: "CVEs",
//   icon: LibraryBooks,
//   component: CVEXSearch,
//   layout: "/main",
//   visible: true,
//   dynamic_render:true
// },
const dashboardRoutes = [
  {
    path: "/scoreboard",
    name: "Scoreboard",
    icon: Dashboard,
    component: ScoreboardPage,
    layout: "/main",
    visible: true,
    dynamic_render:true
  },
  {
    path: "/user",
    name: "User Profile",
    icon: Person,
    component: UserProfile,
    layout: "/main",
    visible: false,
    dynamic_render:true
  },
  {
    path: "/search",
    name: "CVE Search",
    icon: Person,
    component: CVEXSearch,
    layout: "/main",
    visible: true,
    dynamic_render:false
  },
  {
    path: "/howto",
    name: "How To Create a CVEX",
    icon: LibraryBooks,
    component: CVEXInfo,
    layout: "/main",
    visible: true,
    dynamic_render:true
  },
  {
    path: "/runinfo/:id",
    name: "How To Run a CVEX",
    icon: LibraryBooks,
    component: CVEXRunInfo,
    layout: "/main",
    visible: true,
    dynamic_render:true
  },
  {
    path: "/prereqs",
    name: "CVEXecute Requirements",
    icon: Computer,
    component: CVEXPrereqs,
    layout: "/main",
    visible: true,
    dynamic_render:true
  },
  {
    path: "/users/:id",
    name: "Users",
    icon: UserIcon,
    component: UserList,
    layout: "/main",
    visible: true,
    dynamic_render:true
  }

];

export default dashboardRoutes;
