import React from "react";

//import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import SBTable from "components/Table/SBTable.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import { withStyles } from '@material-ui/core/styles';

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

var searchQuery = require('lucene-query-parser');

// const theStyles = makeStyles(styles);
// const classes = theStyles();

class CVEXSearch extends React.Component {
  constructor(props) {
    super(props);
    // this.state ={
    //   searchValue: props.searchValue
    // }
  }

  queryToJson(ast, json={}){
    if (ast.left){
      json['left'] = {field: ast.left.field, term: ast.left.term }
    }
    json['operator'] = ast.operator
    if(ast.right){
      if (ast.right.left){
        console.log("going to child")
        json['right'] = {}
        this.queryToJson(ast.right, json.right);
      } else {
        json['right'] = {field: ast.right.field, term: ast.right.term};
      }
    }
    return json
  }

  render() {

    //var options = {keywords: ['keywords','cve','cvex'], ranges:['date']}
    var queryObj = searchQuery.parse(this.props.searchValue)
    let jsonQuery = this.queryToJson(queryObj);
    let jsonStringified = JSON.stringify(jsonQuery)

    console.log(`CVEX rendered json str: `, JSON.stringify(jsonQuery));
    const {classes} = this.props;
    return (
      <div >
        <Card>
          <CardHeader color="info4" className={classes.searchCardHeader}>
            <h3 className={classes.searchTableWhite}>CVE Search Results</h3>
          </CardHeader>
          <SBTable
            tableHeaderColor="primary"
            tableHead={["Rank", "CVE", "Short Desc", "Bounties"]}
            filter="search"
            searchValue={jsonStringified}
          />
        </Card>

      </div>
    );
  }
  static get propTypes() {
    return {
      classes: PropTypes.any,
      searchValue: PropTypes.any,
    };
  }
}

export default withStyles(styles)(CVEXSearch);