import React, {Component} from "react";
import PropTypes from "prop-types";
// @material-ui/core components
//import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box/Box'
import { withStyles } from '@material-ui/core/styles';
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import { Link } from 'react-router-dom'

//custom components
import Button from "components/CustomButtons/Button.js";
import ArrowForward from "@material-ui/icons/ArrowForwardIos";
import ArrowBack from "@material-ui/icons/ArrowBackIos";

import CVEXDetails from "views/CVEX/CVEXDetails"
import jconf from 'cvex-config.json'

//const useStyles = makeStyles(styles);

const tableHeaderColor = "info";
let tableHead = [ "Rank", "CVE", "Short Desc", "Bounties", "Upvote"];

// const mbstyle = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };

class SBTable extends Component {
  static searchValue = ""
  constructor(props){
    super(props);
    if (this.state){
      console.log("SBTable:Constructor ", props.searchValue, SBTable.searchValue, "odeets=", this.state.openDetails)
    } else {
      console.log("SBTable:Constructor ", props.searchValue, SBTable.searchValue)
    }
    this.state = {
      tableData: [],
      openDetails: false,
      userMsg: "",
      page:0,
      tableRowCount: 0
    };
    this.cve_db_id = "84c24f80-24f5-4cfe-9f51-b60c1c5a05db"
    this.base_url = window.location.href

    this.moveNext = this.moveNext.bind(this);
    this.moveBack = this.moveBack.bind(this);
    this.loadSBData = this.loadSBData.bind(this);
    this.userMsg = this.userMsg.bind(this);
    if (props.tableHead){
      tableHead = props.tableHead;
    }
  }

  componentDidMount() {
    console.log("componentDidMount fired")
    document.title = "CVEX Scoreboard";
    SBTable.searchValue = this.props.searchValue
    this.loadSBData(0);
  }

  loadSBData(page=-1){
    var jsonout = {}
    if (page === -1){
      let firstindex = this.state.tableData[0][1] - 1;
      if ((firstindex % 50) === 0) {
        page = (firstindex / 50) ;
      } else{
        page = 0
      }
    }
    let url = `${jconf.DB_API}/sbdata/${this.props.filter}/${page}`
    if (this.props.searchValue){
      url = `${jconf.DB_API}/sbdata/${this.props.filter}/${page}` + `?searchvalue=${this.props.searchValue}`
    }
    console.log("THE URL = ", url);
    fetch(url, {method: 'GET',})
      .then(response => response.json().then(body => ({body, status: response.status})))
      .then(({body, status}) => {

        if (status !== 200) {
          console.log(body.message);
          return;
        }
        console.log(body.tableRowCount);
        let maxpage = parseInt(Math.ceil(body.tableRowCount / 50) )
        console.log("page=",page, "maxpage=",maxpage, "tableRowCnt", body.tableRowCount)
        this.setState({
          tableData:body.tableData, userMsg:"", maxpage: maxpage, page: page,
          leftDisabled: page === 0, rightDisabled: (page+1) === maxpage
        })
        jsonout=body;

      })
      .catch((error) => {
        console.log(error);
      });

    return jsonout;
  }

  upVote(event) {
    const cve_db_id = event.target.getAttribute('cve_db_id');
    console.log("Doing upvote!", event, cve_db_id)
    let url = `${jconf.DB_API}/upvote?cid=${cve_db_id}`;
    fetch(url, {
      method: 'GET',
    })
      .then(response => response.json().then(body => ({body, status: response.status})))
      .then(({body, status}) => {
        if (status !== 200) {
          console.log(status);
          console.log(body.message);
          return;
        }
        if (body.response === "Upvote succeeded"){
          this.loadSBData();
        } else {
          console.log("Upvote FAILED")
          this.setState({userMsg:"Upvote failed most likely because you are not logged in"})
        }
      })
      .catch((error) => {
        console.log(error);
      });

    event.preventDefault();
  }
  handleDetailsOpen = (event) => {
    event.preventDefault();
    //const cve_db_id = event.target.getAttribute('cve_db_id');
    //console.log(cve_db_id)
    this.cve_db_id = event.target.getAttribute('cve_db_id');

    console.log("SBTable:handleOpen  ", this.cve_db_id)

    this.setState({openDetails: true})
  }
  handleDetailsClose = () => {
    console.log("SBTable:handleDetailsCLOSE --------------------------", this.state.openDetails)
    this.setState({openDetails: false})
  }
  moveNext() {
    if (this.state.tableData.length === 0){
      return;
    }
    let lastindex = this.state.tableData[this.state.tableData.length -1][1];
    console.log(`lastinex = ${lastindex}`)
    if (lastindex % 50 === 0){
      let nextPage = lastindex / 50;
      this.loadSBData(nextPage)
    }
  }

  moveBack() {
    if (this.state.tableData.length === 0){
      return;
    }
    let firstindex = this.state.tableData[0][1] - 1;
    if ((firstindex % 50) === 0 && firstindex > 0){
      let priorPage = (firstindex / 50) - 1;
      this.loadSBData(priorPage)
    }
  }
  userMsg(classes){

    console.log(`userMsg = ${this.state.userMsg}`)
    if (this.state.userMsg){
      setTimeout(() => {
        this.setState({userMsg:""})
      }, 5000);
      return (<div className={classes.usermsg} id="usermsg">{this.state.userMsg}</div>)
    }
    return "";
  }
  render() {
    const {classes} = this.props;
    let current = this.state ;
    let searchCols = ""
    let header_cell_classes = classes.tableCell + " " + classes.tableHeadCell;
    
    if (this.props.searchValue && this.props.searchValue !== SBTable.searchValue) {
      console.log("SBTable: about to load data", this.props.searchValue, SBTable.searchValue)
      SBTable.searchValue = this.props.searchValue
      this.loadSBData(0)
    }
    let topbuttonstyle = classes.buttonboxtop
    if (this.props.searchValue){
      //console.log("We have a search value", current.tableData.length, current.tableData[0].length)
      topbuttonstyle = classes.buttonboxtopSearch
      if (current.tableData.length){
        console.log("We have a search value", current.tableData.length, current.tableData[0].length)
      }
      if (current.tableData.length > 0 && current.tableData[0].length === 6){
        searchCols = (
          <TableCell className={header_cell_classes} key={4}>CVEX</TableCell>
        )
      }
    }
    
    console.log("SBTable:rendering", this.props.searchValue, "odeets=", this.state.openDetails, this.cve_db_id);
    
    return (
      <div>
        <Modal
        className={classes.modal}
        open={this.state.openDetails}
        onClose={this.handleDetailsClose}
      >
          <Box className={classes.modalbox}>
            <CVEXDetails cve_db_id={this.cve_db_id}/>
          </Box>
      </Modal>
        {this.userMsg(classes)}
      <div className={classes.tableResponsive}>
        
        <div className={topbuttonstyle}>
          <div className={classes.positiondata}>
            <span>{this.state.page + 1 } of {this.state.maxpage}</span>
          </div>
          
          <Button id="topforward" color="info2" aria-label="edit" justIcon round
                  className={classes.dirbuttontop}
                  onClick={this.moveNext}
                  disabled={this.state.rightDisabled}
          >
            <ArrowForward />
          </Button>
          <Button id="topback" color="info2" justIcon round
                  className={classes.dirbuttontop}
                  onClick={this.moveBack}
                  disabled={this.state.leftDisabled}
          >
            <ArrowBack />
          </Button>

        </div>
        <div className={classes.buttonboxbottom}>
          <Button id="backbottom" color="info3" aria-label="edit" justIcon round
                  className={classes.dirbuttonbottom}
                  onClick={this.moveBack}
                  disabled={this.state.leftDisabled}
          >
            <ArrowBack />
          </Button>
          <Button id="forwardbottom" color="info3" aria-label="edit" justIcon round
                  className={classes.dirbuttonbuttom}
                  onClick={this.moveNext}
                  disabled={this.state.rightDisabled}
          >
            <ArrowForward />
          </Button>

        </div>
        <Table className={classes.table}>
          {tableHead !== undefined ? (
            <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
              <TableRow className={classes.tableHeadRow}>
                {tableHead.map((prop, key) => {
                  return (
                    <TableCell className={header_cell_classes} key={key}>
                      {prop}
                    </TableCell>
                  );
                })}
                {searchCols}
              </TableRow>
            </TableHead>
          ) : null}
          <TableBody>
            {current.tableData.map((sbrow, key) => {
              
              
              let upvote = (
                <TableCell>
                  <a href="" onClick={this.upVote.bind(this)} cve_db_id={sbrow[0]} className={classes.arrowcolor}> △</a>
                </TableCell>
              );
              if (this.props.filter === "completed"){
                upvote = "";
              }
              return (
                <TableRow key={key} className={classes.tableBodyRow}>
                  {sbrow.slice(1).map((prop, key) => {
                    let cell_classes = classes.tableCell + " ";
                    if (key === 1 || (this.props.filter ===  "completed" && key === 2)){
                      cell_classes += classes.cvexcell
                    }
                    let tCell = (
                      <TableCell className={cell_classes} key={key}>
                        <a href="" onClick={this.handleDetailsOpen.bind(this)} cve_db_id={sbrow[0]} className={classes.rowcolor}>
                          {prop}
                        </a>
                      </TableCell>
                    )
                    if ((this.props.filter === "completed" && key === 1) || (this.props.filter==="search" && key === 4)){ // CVEX ID display, 2 lower b/c of slice
                      tCell = (
                        <TableCell className={cell_classes} key={key}>
                          <Link to={"/main/runinfo/"+ prop} className={classes.cvexlink}>
                            <span>{prop}</span>
                          </Link>
                        </TableCell>
                      )
                    }
                    return tCell;
                  })}
                  {upvote}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      </div>
    );
  }
}

SBTable.defaultProps = {
  tableHeaderColor: "gray",
};

SBTable.propTypes = {
  classes: PropTypes.any,
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  filter: PropTypes.oneOf(["popular","completed","new","search"]),
  searchValue: PropTypes.any
};

export default withStyles(styles)(SBTable);