import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
//import Notifications from "@material-ui/icons/Notifications";
import Dashboard from "@material-ui/icons/Dashboard";
import Search from "@material-ui/icons/Search";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import jconf from "cvex-config.json"

const useStyles = makeStyles(styles);
var isLoggedIn = false;
var username = "";
export default function AdminNavbarLinks(props) {
  const classes = useStyles();
  const history = useHistory();

  const [openSearchHelp, setOpenSearchHelp] = React.useState(null);
  const [openProfile, setOpenProfile] = React.useState(null);

  const handleClickNotification = (event) => {
    if (openSearchHelp && openSearchHelp.contains(event.target)) {
      setOpenSearchHelp(null);
    } else {
      setOpenSearchHelp(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenSearchHelp(null);
  };
  const handleClickProfile = (event) => {

    console.log(openProfile, event.target)
    if (openProfile && openProfile.contains(event.target)) {
      console.log("Contains means click inside.")
      setOpenProfile(null);
    } else {
      console.log("Contains means click outside.", event.currentTarget.textContent)
      setOpenProfile(event.currentTarget);
    }
  };
  const handleClickProfileMI = () => {
    setOpenProfile(null);
    console.log("closed menu, LOGGING IN.")
  };
  const handleClickLogoutMI = () => {
    setOpenProfile(null);
    console.log("closed menu, doing logout")
    let url = `${jconf.DB_API}/logout`
    fetch(url, {method: 'GET',})
      .then(response => {
        isLoggedIn = false;
        username = "";
        console.log(response.status)
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleClickLoginMI = () =>{
    setOpenProfile(null);

    let ret_url=`${window.location.origin}/main/scoreboard`
    const githubAuthURL = `${jconf.DB_API}/login?sb_ret=${ret_url}`;
    console.log(githubAuthURL);
    window.location.href=githubAuthURL;
  }
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };


  let url = `${jconf.DB_API}/isloggedin`
  fetch(url, {method: 'GET',})
    .then(response => response.json().then(body => ({body, status: response.status})))
    .then(({body, status}) => {

      console.log(status);
      if (status !== 200) {
        console.log(body.message);
        isLoggedIn = false;
        return;
      }
      if(body.response){
        console.log("Logged In, ", body.response);
        isLoggedIn = true;
        username = body.username;
      } else {
        console.log("Not Logged In ", body);
        isLoggedIn = false
      }

    })
    .catch((error) => {
      console.log(error);
    });

  function UserOptions(){


    if (isLoggedIn) {
      return (<Paper>
        <ClickAwayListener onClickAway={handleCloseProfile}>
          <MenuList role="menu">
            <MenuItem
              onClick={handleClickProfileMI}
              className={classes.dropdownItem}
            >
              Logged in as <b>{username}</b>
            </MenuItem>
            <Divider light/>
            <MenuItem
              onClick={handleClickLogoutMI}
              className={classes.dropdownItem}
            >
              Logout
            </MenuItem>
          </MenuList>
        </ClickAwayListener>
      </Paper>);
    } else {
      return (<Paper>
        <ClickAwayListener onClickAway={handleCloseProfile}>
          <MenuList role="menu">
            <MenuItem
              onClick={handleClickLoginMI}
              className={classes.dropdownItem}
            >
              Login using GitHub
            </MenuItem>
          </MenuList>
        </ClickAwayListener>
      </Paper>);
    }
  }
  return (
    <div>
      <div className={classes.searchWrapper}>
        <CustomInput
          id="search_input"
          formControlProps={{
            className: classes.margin + " " + classes.search,
          }}
          inputProps={{
            placeholder: "Search",
            inputProps: {
              "aria-label": "Search",
            },
          }}
          onKeyPress={event => {
            if (event.key === 'Enter') {
              history.push("/main/search")
              props.handleSearch();
            }
          }}
        />
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openSearchHelp ? "notification-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickNotification}
          className={classes.buttonLink}
          style={{marginLeft:"-20px"}}
        >
          
          <span className={classes.helpbutton}>?</span>
          
        </Button>
          <Poppers
            open={Boolean(openSearchHelp)}
            anchorEl={openSearchHelp}
            transition
            disablePortal
            className={
              classNames({ [classes.popperClose]: !openSearchHelp }) +
              " " +
                    classes.popperNav
                }
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    id="notification-menu-list-grow"
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleCloseNotification}>
                        <div className={classes.popup}>
                          <h4>Search Help</h4>
                          By default, the non-tagged keywords are searched for in the CVE descriptions.<br/>
                          The search can use the booleans AND/OR (must be uppercase, defaults to AND if omitted).<br/>
                          Search supports:
                          <ul>
                            <li>&quot;after:MM-DD-YYYY&quot; and &quot;before:MM-DD-YYYY&quot;, which filters on the publication date.</li>
                            <li>&quot;cve:&quot;, e.g., &quot;cve:2021-11&quot;, will find all the CVE IDs in the year 2021 that start with 11.</li>
                            <li>&quot;cvex:&quot;, e.g., &quot;cvex:210825&quot;, will find all the CVEX&apos;s created on August 25, 2021.</li>
                            <li>&quot;has_cvex:&quot;, e.g., &quot;has_cvex&quot;, will find all the CVE&apos;s that have an associated CVEX.</li>
                          </ul>
                          
                          
                        </div>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Poppers>
        <Button id="searchbutton" color="white" aria-label="edit" justIcon round
                onClick={props.handleSearch} component={Link} to="/main/search">
          <Search />
        </Button>
      </div>
      <Button
        color={window.innerWidth > 959 ? "transparent" : "white"}
        justIcon={window.innerWidth > 959}
        simple={!(window.innerWidth > 959)}
        aria-label="Dashboard"
        className={classes.buttonLink}
      >
        
        <Dashboard className={classes.icons} />
        <Hidden mdUp implementation="css">
          <p className={classes.linkText}>Dashboard</p>
        </Hidden>
      </Button>
      
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classes.buttonLink}
        >
          <Person className={classes.icons} />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Profile</p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <UserOptions/>
            </Grow>
          )}
        </Poppers>

      </div>
    </div>
  );
}

AdminNavbarLinks.propTypes = {
  handleSearch: PropTypes.func
};
