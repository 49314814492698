import React from "react";
// react plugin for creating charts
//import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
//import Warning from "@material-ui/icons/Warning";
//import DateRange from "@material-ui/icons/DateRange";
//import LocalOffer from "@material-ui/icons/LocalOffer";
//import Update from "@material-ui/icons/Update";
// import ArrowUpward from "@material-ui/icons/ArrowUpward";
// import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import Popular from "@material-ui/icons/TrendingUp";
import New from "@material-ui/icons/FirstPage";
import Completed from "@material-ui/icons/AttachMoneyTwoTone";
//import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import SBTable from "components/Table/SBTable.js";
import UserTable from "components/Table/UserTable.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
//import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
//import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
//custom components

const useStyles = makeStyles(styles);
import jconf from 'cvex-config.json'

export default function Scoreboard() {
  const [state, setState] = React.useState([])
  React.useEffect(() => {
    console.log("FETCHING DATA FOR YOU!!!!!!!!")
    let url = `${jconf.DB_API}/topdata`
    fetch(url, {method: 'GET',})
      .then(response => response.json().then(body => ({body, status: response.status})))
      .then(({body, status}) => {
        if (status !== 200) {
          console.log(body.message);
          return;
        }
        setState(body)
      })
      .catch((error) => {
        console.log(error);
      });
  }, [])
  const classes = useStyles();

  var upvote_cnt = 0
  var upvote_user = "nobody"
  if (state.most_upvotes && state.most_upvotes.length === 2){
    upvote_cnt = state.most_upvotes[0]
    upvote_user = state.most_upvotes[1]
  }
  return (
    <div className={classes.banners}>
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card className={classes.bannerCard}>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Icon>content_copy</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>CVEX&quot;s added over the last year</p>
              <h3 className={classes.cardTitle}>
                {state.cvexs_added_in_last_year}
              </h3>
            </CardHeader>
            {/*<CardFooter stats>*/}
            {/*  <div className={classes.stats}>*/}
            {/*    <Danger>*/}
            {/*      <Warning />*/}
            {/*    </Danger>*/}
            {/*  </div>*/}
            {/*</CardFooter>*/}
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card className={classes.bannerCard}>
            <CardHeader color="info2" stats icon>
              <CardIcon color="info2">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>Sent Most Upvotes</p>
              <h3 className={classes.cardTitle}>{upvote_user} {upvote_cnt} </h3>
            </CardHeader>
            {/*<CardFooter stats>*/}
            {/*  <div className={classes.stats}>*/}
            {/*    <DateRange />*/}
            {/*    Last 24 Hours*/}
            {/*  </div>*/}
            {/*</CardFooter>*/}
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card className={classes.bannerCard}>
            <CardHeader color="info" stats icon>
              <CardIcon color="info3">
                <Accessibility />
              </CardIcon>
              <p className={classes.cardCategory}>Total Users</p>
              <h3 className={classes.cardTitle}>{state.total_users}</h3>
            </CardHeader>
            {/*<CardFooter stats>*/}
            {/*  <div className={classes.stats}>*/}
            {/*    <LocalOffer />*/}
            {/*    Tracked from Github*/}
            {/*  </div>*/}
            {/*</CardFooter>*/}
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card className={classes.bannerCard}>
            <CardHeader color="info" stats icon>
              <CardIcon color="info4">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Total CVEs</p>
              <h3 className={classes.cardTitle}>{state.total_cves}</h3>
            </CardHeader>
            {/*<CardFooter stats>*/}
            {/*  <div className={classes.stats}>*/}
            {/*    <Update />*/}
            {/*    Just Updated*/}
            {/*  </div>*/}
            {/*</CardFooter>*/}
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <CustomTabs
            title="Filters:"
            headerColor="info4"
            tabs={[
              {
                tabName: "CVEXs",
                tabIcon: Completed,
                tabDesc: "List CVEXs and their related CVEs",
                tabContent: (
                  <SBTable
                    tableHeaderColor="primary"
                    tableHead={["Rank","CVEX", "CVE", "Short Desc"]}
                    filter="completed"
                  />
                ),
              },
              {
                tabName: "Popular CVEs",
                tabDesc: "Lists the CVEs with the highest bounty and no CVEX.",
                tabIcon: Popular,
                tabContent: (
                  <SBTable
                    tableHeaderColor="primary"
                    tableHead={["Rank","CVE", "Short Desc", "Bounties","Upvote"]}
                    filter="popular"
                  />
                ),
              },
              {
                tabName: "New CVEs",
                tabIcon: New,
                tabDesc: "Lists the most recently added CVEs.",
                tabContent: (
                  <SBTable
                    tableHeaderColor="primary"
                    tableHead={["Rank","CVE", "Short Desc", "Bounties", "Upvote"]}
                    filter="new"
                  />
                ),
              },
              
            ]}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card className={classes.cardminwidth}>
            <CardHeader color="info4" className={classes.bannerheight}>
              <h4 className={classes.cardTitleWhite}>Top Contributors</h4>

            </CardHeader>
            <CardBody>
              <UserTable
                tableHeaderColor="info2"
                tableHead={["Rank", "Name", "Points", "CVEXs"]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
