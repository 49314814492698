import React from "react";
import { useParams } from 'react-router-dom';

// react plugin for creating charts
//import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
//custom components

const useStyles = makeStyles(styles);
import jconf from 'cvex-config.json'

export default function CVEXRunInfo() {
  const [state, setState] = React.useState([])
  const classes = useStyles();
  var {id} = useParams();
  let contentTitle;
  let preamble = "";
  if (id === ":id"){
    id="CVEX-210825-001";
    contentTitle = "How to Run a CVEX"
    preamble = (
      <div className={classes.note}>
        The example below demonstrates how to run {id} using the CVEXecute container.
      </div>
    )
    
  } else {
    contentTitle = `Running ${id}`
    
  }
  console.log("ID is ", id);
  React.useEffect(() => {
    console.log("FETCHING DATA FOR YOU!!!!!!!!")
    //let url = `${jconf.DB_API}/getcvex/${id}`
    let url = `${jconf.DB_API}/get_docker_compose/${id}`
    fetch(url, {method: 'GET',})
      .then(response => response.json().then(body => ({body, status: response.status})))
      .then(({body, status}) => {
        if (status !== 200) {
          console.log(body.message);
          return;
        }
        body.content = atob(body.content)
        setState(body)
      })
      .catch((error) => {
        console.log(error);
      });
  }, [])
  
  
  return (
    <div>
      <Card>
        <CardHeader color="info">
          <h4 className={classes.cardTitleWhite}>{contentTitle}</h4>
          
        </CardHeader>
      
        <CardBody>
          <div className={classes.note}>
            {preamble}
            <h4>Using CVEXecute to safely run the CVEX</h4>
            Although the CVEX can be run from any machine with the proper versions of Docker and Docker Compose, a docker container does not provide sufficient insulation.
            Thus, we recommend you execute CVEXs from inside the CVEXecute container.
            The CVEXecute container spawns a debian-based VM.
            The CVEX is then executed from inside the VM, which should protect the host machine from most threats.
            The CVEXecute container stores the results at /cvex inside the container, which can be mapped to easily access from outside CVEXecute.
            <br/><br/>
            To execute {id} use the following docker command:
            <pre className={classes.precode}><span className={classes.cmdprompt}>$</span> docker run -v /tmp/cvex:/cvex cvex.cs.ucsb.edu/cvexecute {id}</pre>
            The docker container,
            Using the command above, the results will be saved in /tmp/cvex/{id}/results.
            The results folder should contain a <b>collector-MM-DD-HH-MM-SS.pcap</b> file and a <b>target-sysexec.trace.</b>
            <br/>
            By default, the container will exit once the pcap is copied to the results folder.
            <br/>
            If you want to keep the container and VM running after the CVEX completes then add the <b>--stay</b> argument at the end of the docker run command.
            <pre className={classes.precode}><span className={classes.cmdprompt}>$</span> docker run -v /tmp/cvex:/cvex cvex.cs.ucsb.edu/cvexecute {id} --stay</pre>
            
            You can access the VM by connecting to the UNIX socket inside the docker container that is located at /tmp/qemu-lan-1.serial.
            In linux, you can access the VM&apos;s console GUI by adding the X11 access and the <b>--debug</b> argument at the end of the docker run command
            <pre className={classes.precode}><span className={classes.cmdprompt}>$</span> docker run -it --name cvexecute -v /tmp/cvex:/cvex -e DISPLAY=$DISPLAY -v /tmp/.X11-unix:/tmp/.X11-unix cvex.cs.ucsb.edu/cvexecute {id} --debug --stay</pre>
            From inside the VM, the /opt/cvex_worker.py executes the CVEX and logs some details to /var/log/cvex_worker.log.
            <br/>
            If using the --stay argument, additional CVEXs can be executed in a single run by invoking /load_cvex.py. For example:
            <pre className={classes.precode}><span className={classes.cmdprompt}>$</span> docker exec -it cvexecute /load_cvex.py {id} target</pre>
            <br/>
            {/*1. To run {id}, download the <a href={state.url} target="_blank" rel="noopener noreferrer"> docker-compose.yml</a> (<a href={state.download_url} download >download</a>) file to a machine that has both Docker and Docker Compose installed.<br/>*/}
            {/*2. Create a directory and save the docker-compose.yml into it. <br/>*/}
            {/*3. Run <pre>docker-compose up</pre>*/}
            {/*4. After the exploit lands, there should be traffic in a pcap file located in /tmp.*/}
          </div>
        </CardBody>
        
      </Card>
      <Card>
        <CardHeader color={"info"}>
          The CVEX Docker Compose for {id}
        </CardHeader>
        <CardBody>
          <pre>
          {state.content}
          </pre>
        </CardBody>
      </Card>
    </div>
  );
}
