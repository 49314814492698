import React from "react";

// react plugin for creating charts
//import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
//custom components

const useStyles = makeStyles(styles);

export default function CVEXPrereqs() {
  
  const classes = useStyles();
  
  return (
    <div>
      <Card>
        <CardHeader color="info">
          <h4 className={classes.cardTitleWhite}>Prerequisites for Running CVEXecute</h4>
        
        </CardHeader>
        
        <CardBody>
          <div className={classes.note}>
            <h4>CVEXecute Requirements</h4>
            The CVEXecutor container is known to run when the following requirements are met.<br/>
            <ol>
              <li className={classes.lineitemheading}>An Internet Connection</li>
              <li className={classes.lineitemheading}>Ubuntu 20.04 (we have only tested it on this version of Ubuntu)</li>
              <li className={classes.lineitemheading}>Install Docker 20.10</li>
              <div>
                <pre className={classes.precode}><span className={classes.cmdprompt}>$</span> sudo apt-get update</pre>
                <div className={classes.subheading}>Setup the apt-get repository</div>
                <pre className={classes.precode}><span className={classes.cmdprompt}>$</span> sudo apt-get install ca-certificates curl gnupg lsb-release</pre>
                <pre className={classes.precode}><span className={classes.cmdprompt}>$</span> curl -fsSL https://download.docker.com/linux/ubuntu/gpg | sudo gpg --dearmor -o /usr/share/keyrings/docker-archive-keyring.gpg</pre>
                <pre className={classes.precode}><span className={classes.cmdprompt}>$</span> echo &quot;deb [arch=$(dpkg --print-architecture) signed-by=/usr/share/keyrings/docker-archive-keyring.gpg] https://download.docker.com/linux/ubuntu $(lsb_release -cs) stable&quot; | sudo tee /etc/apt/sources.list.d/docker.list &gt; /dev/null</pre>
                <div className={classes.subheading}>Install Docker</div>
                <pre className={classes.precode}><span className={classes.cmdprompt}>$</span> sudo apt-get update</pre>
                <pre className={classes.precode}><span className={classes.cmdprompt}>$</span> export DOCKER_VER=$(apt-cache madison docker-ce|cut -d &quot;|&quot; -f2|egrep -oh &quot;5:20.10.*-focal&quot;|head -1)</pre>
                <pre className={classes.precode}><span className={classes.cmdprompt}>$</span> sudo apt-get install docker-ce=&quot;$DOCKER_VER&quot; docker-ce-cli=&quot;$DOCKER_VER&quot; containerd.io</pre>
              </div>
              <li className={classes.lineitemheading}>Install Docker Compose 1.29</li>
              <div>
                <pre className={classes.precode}><span className={classes.cmdprompt}>$</span> sudo curl -L &quot;https://github.com/docker/compose/releases/download/1.29.2/docker-compose-$(uname -s)-$(uname -m)&quot; -o /usr/local/bin/docker-compose</pre>
                <pre className={classes.precode}><span className={classes.cmdprompt}>$</span> sudo chmod +x /usr/local/bin/docker-compose</pre>
                <pre className={classes.precode}><span className={classes.cmdprompt}>$</span> sudo ln -s /usr/local/bin/docker-compose /usr/bin/docker-compose</pre>
              </div>
              <li className={classes.lineitemheading}>Create docker group and add current user to it</li>
              <div>
                <pre className={classes.precode}><span className={classes.cmdprompt}>$</span>sudo groupadd docker</pre>
                <pre className={classes.precode}><span className={classes.cmdprompt}>$</span>sudo usermod -aG docker $USER</pre>
                <pre className={classes.precode}><span className={classes.cmdprompt}>$</span>newgrp docker</pre>
              </div>
              <li className={classes.lineitemheading}>Test</li>
              <div>
                <pre className={classes.precode}><span className={classes.cmdprompt}>$</span>docker run hello-world</pre>
                The command above should respond with &quot;Hello from Docker...&quot; and you should be able to run a CVEX on the current machine.
              </div>
            </ol>
            
          </div>
        </CardBody>
      
      </Card>
      
    </div>
  );
}
