import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { withStyles } from '@material-ui/core/styles';

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

// cvex modules

import CustomDataField from "components/CustomDataField/CustomDataField"
import jconf from 'cvex-config.json'

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

class  CVEXDetails extends React.Component {
  // this one runs on initial load of component
  constructor(props) {
    super(props);
    this.state ={
      cveDetails: {}
    }
  }

  componentDidMount() {
    this.loadCVEDetails();
  }

  loadCVEDetails() {
    let url = `${jconf.DB_API}/cvedetails/${this.props.cve_db_id}`;
    console.log("loadCVEDetails: URL", url);
    fetch(url, {method: 'GET',})
      .then(response => response.json().then(body => ({body, status: response.status})))
      .then(({body, status}) => {

        console.log(status);
        if (status !== 200) {
          console.log(body.message);
          return;
        }
        console.log("BODY response is ", body)
        this.setState({cveDetails: body})
      })
      .catch((error) => {
        console.log(error);
      });
    return {}
  }

  render() {
    const {classes} = this.props;
    const cveDetails = this.state.cveDetails;
    console.log(`CVEXDetails Rendering`, this.props.cve_db_id, cveDetails)

    const pluralBounty = cveDetails.bounty > 1 ? 's' : '';
    return (
      <div>
        {/*<GridContainer>*/}
        {/*  <GridItem xs={12} sm={12} md={8}>*/}
        <Card>
          <CardHeader color="info4">
            <h4 className={classes.cardTitleWhite}>{cveDetails.cveid}</h4>
            <p className={classes.cardCategoryWhite}>Additional information about the CVE</p>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                <CustomDataField
                  labelText="CVE ID"
                  value={cveDetails.cveid}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <CustomDataField
                  labelText="CVEX Exists"
                  value={`${cveDetails.paid}`}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <CustomDataField
                  labelText="Current Bounty"
                  value={`${cveDetails.bounty} point${pluralBounty}`}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <CustomDataField
                  labelText="Exploitability Score"
                  value={cveDetails.exploitabilityScore}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <CustomDataField
                  labelText="CVE Description"
                  id="cve-description"
                  value={cveDetails.description}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                <CustomDataField
                  labelText="Attack Complexity"
                  value={cveDetails.attackComplexity}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <CustomDataField
                  labelText="Attack Vector"
                  value={cveDetails.attackVector}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <CustomDataField
                  labelText="Privileges Required"
                  value={cveDetails.privilegesRequired}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <CustomDataField
                  labelText="User Interaction"
                  value={cveDetails.userInteraction}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                <CustomDataField
                  labelText="Confidentiality Impact"
                  value={cveDetails.confidentialityImpact}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <CustomDataField
                  labelText="Integrity Impact"
                  value={cveDetails.integrityImpact}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <CustomDataField
                  labelText="Availability Impact"
                  value={cveDetails.availabilityImpact}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <CustomDataField
                  labelText="User Interaction"
                  value={cveDetails.userInteraction}
                />
              </GridItem>
            </GridContainer>

          </CardBody>

        </Card>

      </div>
    );
  }
}

CVEXDetails.propTypes = {
  classes: PropTypes.any,
  cve_db_id: PropTypes.string
};

export default withStyles(styles)(CVEXDetails)